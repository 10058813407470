import { Toast } from './components.ts';

(function () {
    // 获取flash消息提示的标签对象
    const flashNotice = document.querySelector('#flash_notice');
    // 当存在flash消息提示时，创建Toast弹窗
    if (flashNotice){
        let flashType = flashNotice.getAttribute('data-flash-type') || TEXT.toast.type.success;
        let flashContext = flashNotice.getAttribute('value') || TEXT.toast.invalid_message;
        new Toast(flashType, flashContext);
    }
})()