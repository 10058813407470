import '../packs-js/search/jquery_cookie';
import { global, $ } from './common/_all';
import { ConImg } from './common/con_image';
// import { Toast } from './components/toast';

document.addEventListener("DOMContentLoaded", function () {
    let conImg = new ConImg();
    conImg.initLoading();

    // new Toast('success', 'Load success!');
});

$(function () {
    setTimeout(function () {

        $(window).scroll(function () {
            const windowY = $(window).height();
            const scrollTop = $(window).scrollTop();
            const footerY = $('footer').offset().top;

            if (scrollTop > 400) {
                $('#go_top').removeClass('hidden');
            } else {
                $('#go_top').addClass('hidden');
            }

            if (scrollTop + windowY > footerY) {
                const newBottom = (scrollTop + windowY - footerY);
                $('#go_top').addClass('fixed').css('bottom', newBottom + 'px').removeClass('bottom-0');
            } else {
                $('#go_top').addClass('fixed bottom-0').removeClass('absolute').css('bottom', '');
            }
        });
    }, 400);

    // 回到顶部
    $('#go_top').on("click", function () {
        $("body,html").animate({
            scrollTop: 0
        }, 500);
    });
});