import {PopSearchInput, updateSearchHistoryData} from './search/modal_pop_search';
import { $, MstCom } from './common/_all.js';

$(function () {
    var searchBox = document.getElementById('searchbox');
    let _input = $('#search');
    _input.on('focus', function () {
        searchBox.classList.add('focused'); // 当输入框获得焦点时添加类
    });
    _input.on('blur', function () {
        searchBox.classList.remove('focused'); // 当输入框失去焦点时移除类
    });
    new PopSearchInput(_input);
    $('#pop_search_btn').on('click', function () {
        updateSearchHistoryData('history', $.trim($(this).closest('form').find('input[name="keywords"]').val()));
    });

    document.getElementById('search').addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
            document.getElementById('pop_search_btn').click();
        }
    });

    $('#pop_search_btn').closest('form').on('submit', function () {
        updateSearchHistoryData('history', $.trim($(this).find('input[name="keywords"]').val()));
    });

    $('.tt-dataset-stock').on('click','.history',function (){
        var formObj = $('#search_form');
        formObj.find('input[name="keywords"]').val(this.textContent);
        $('<input>').attr({
            type: 'hidden',
            name: 'authenticity_token',
            value: $("meta[name=csrf-token]").attr("content")
        }).appendTo(formObj);
        formObj.submit();
    })
});

const option1 = document.getElementById("CNY");
const option2 = document.getElementById("USD");
const option3 = document.getElementById("JPY");

option1.checked = true;  

document.addEventListener("DOMContentLoaded", function () {
    let selectedCurrency = localStorage.getItem("selectedCurrency");
    if (!selectedCurrency) {
        // 如果 localStorage 中没有选项，则将默认选项设置为第一个选项（CNY）
        selectedCurrency = "CNY";
        localStorage.setItem("selectedCurrency", selectedCurrency);
    }

    // 根据选项设置单选按钮的选中状态
    option1.checked = selectedCurrency === "CNY";
    option2.checked = selectedCurrency === "USD";
    option3.checked = selectedCurrency === "JPY";

    // 更新结果显示
    updateCurrencyResult();
});

// 添加事件监听器，当单选按钮的状态变化时更新 localStorage 中的选项值
option1.addEventListener("change", function () {
    localStorage.setItem("selectedCurrency", "CNY");
    updateCurrencyResult();
});
option2.addEventListener("change", function () {
    localStorage.setItem("selectedCurrency", "USD");
    updateCurrencyResult();
});
option3.addEventListener("change", function () {
    localStorage.setItem("selectedCurrency", "JPY");
    updateCurrencyResult();
});

 
    function updateCurrencyResult() {
        let selectedCurrency = localStorage.getItem("selectedCurrency");
    
        // Get the divs for each Currency result
        const resultCNY = $('#result_CNY');
        const resultUSD = $('#result_USD');
        const resultJPY = $('#result_JPY');
    
        // Hide all Currency results
        resultCNY.addClass('hidden');
        resultUSD.addClass('hidden');
        resultJPY.addClass('hidden');
        
        // Show the selected Currency result
        if (selectedCurrency === "CNY") {
            resultCNY.removeClass('hidden');
        } else if (selectedCurrency === "USD") {
            resultUSD.removeClass('hidden');
        } else if (selectedCurrency=== "JPY") {
            resultJPY.removeClass('hidden');
        }

    $('#dropdownList2').addClass('hidden');
}

$('#dropdownBtn2').click(function () {
    $(this).next().toggleClass('hidden');
});

function change_currency(currency) {
    const url = '/user/change_currency?currency=' + currency;
    MstCom.fetchJson(url, {
        success: function () {
            window.location.reload(); // 请求成功后刷新页面
        },
        debug: false
    });
}

$('#CNY, #USD, #JPY').click(function () {
    const currency = $(this).attr('id');
    change_currency(currency);
});


$(document).ready(function () {
    // 点击按钮显示/隐藏下拉菜单
    $('#searchopen').on('click', function (event) {
        event.stopPropagation();
        $('#searchopened').toggleClass('hidden');
    });

    // 点击页面其他地方时隐藏下拉菜单
    $(document).on('click', function () {
        $('#searchopened').addClass('hidden');
    });

    // 防止点击下拉菜单时触发隐藏事件
    $('#searchopened').on('click', function (event) {
        event.stopPropagation();
    });
})

document.getElementById('btnSideBar').addEventListener('click', function() {
    document.getElementById('menu_sidebar').classList.remove('hidden');
  });

  document.getElementById('btnSideBarClose').addEventListener('click', function() {
    document.getElementById('menu_sidebar').classList.add('hidden');
  });