export function searchStock(keyWord, jsonData, flag = true) {
    var stock_matches = [];
    var substrRegex = new RegExp(keyWord, 'i');
    Object.keys(jsonData["stock"] ?? {}).forEach(function (i) {
        var str = jsonData["stock"][i];
        if (substrRegex.test(str["sc"]) || substrRegex.test(str["sn"]) || substrRegex.test(str["ssn"])) {
            stock_matches.push({
                'value': str["sn"],
                'id': str["sc"],
                'm_code': str["sm"]
            });
        }
        if (flag && stock_matches.length === 4) return stock_matches;
    });
    return stock_matches;
}

export function searchTheme(keyWord, jsonData, flag = true) {
    var theme_matches = [];
    var substrRegex = new RegExp(keyWord, 'i');
    Object.keys(jsonData["theme"] ?? {}).forEach(function (i) {
        var str = jsonData["theme"][i];
        if (substrRegex.test(str["tn"])) {
            theme_matches.push({
                'value': str["tn"],
                'id': str["ti"]
            });
        }
        if (flag && theme_matches.length === 4) return theme_matches;
    });
    return theme_matches;
}

export function searchIndex(keyWord, jsonData, flag = true) {
    var index_matches = [];
    var substrRegex = new RegExp(keyWord, 'i');
    Object.keys(jsonData["index"] ?? {}).forEach(function (i) {
        var str = jsonData["index"][i];
        if (substrRegex.test(str["name"])) {
            index_matches.push({
                'value': str["in"],
                'id': str["ic"]
            });
        }
        if (flag && index_matches.length === 4) return index_matches;
    });
    return index_matches;
}
