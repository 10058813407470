// 共通定义：
// 定义常量、变量：（本文件中）
// 1. 常量： static CONSTANT_NAME: {type} = xxxx，使用：ConLog.CONSTANT_NAME
// 2. 变量： variableName = xxxx，使用：conlog.VariableName

// ConLog ****** START
export class ConLog {
  // Class constants/variable *** START ***
  static ALLOW_DEBUG: boolean = true; // 允许调试开关，先开启才能调试，否则无法输出debug相关log
  static INS_NAME: string = 'conlog';
  static TEST_DATE_FORMAT: string = '%Y-%m-%d';
  static COLOR: any = {
    enabled: '',
    disabled: '#CCC',
    infoLog: '',
    errorLog: '#F00',
    warnLog:  '#FF8C00',
    debugLog: '#8ED6F9',
  }
  static MSG: any = {
    LOADING_DATA:   'Loading data...',
    NO_MORE:        'No more!',
    BUSY:           'System busy.',
    TIME_OUT:       'Time out!',
    TRY_IT_LATER:   'Please try it later.',
    PERMISSION_DENY:'Permission deny!',
    NOT_BLANK:      'Cannot be empty!',
    OPERATE_SUCCESS:'Operation succeeded!',
    PUB_SUCCESS:    'Published successfully!',
    REPLY_SUCCESS:  'Reply succeeded!',
    PARAMS_ERROR:   'Parameter error!',
    SYS_ERROR:      'System error, please contact the administrator!',
  }

  // Instance constants/variable *** START ***
  debug_flg: boolean = false; // 默认非调试


  // Initialization function
  constructor(opt: any) {
    if (opt == null || typeof(opt) != 'object') opt = {};
    if (ConLog.ALLOW_DEBUG &&  opt.debug === true) {
      this.debug_flg = true;
    }
  }

  setMethodName(methodName): string {
    let _methodName = ConLog.INS_NAME;
    if (methodName) _methodName = _methodName + '.' + methodName
    return '[' + _methodName + '] ';
  }

  // Console log output
  log(msg_list): void {
    // eval('this.info(...arguments)');
    this.info(...arguments);
  }
  info(msg_list): void {
    console.log('%c[INFO]', 'color:' + ConLog.COLOR.infoLog, ...arguments);
  }
  error(msg_list): void {
    console.log('%c[ERROR]', 'color:' + ConLog.COLOR.errorLog, ...arguments);
  }
  warn(msg_list): void {
    console.log('%c[ERROR]', 'color:' + ConLog.COLOR.warnLog, ...arguments);
  }
  debug(msg_list): void {
    if (this.debug_flg) {
      console.log('%c[DEBUG]', 'color:' + ConLog.COLOR.debugLog, ...arguments);
    }
  }
  // 类型错误
  // 例：conlog.typeError({method: xxx, obj: xxx})
  //  typeError: (opt = {}) ->
  //    if typeof(opt['obj']) == 'undefined'
  //      conlog.warn('Log.typeError', 'opt[obj] is not allow null/undefined.')
  //      return
  //    msg_list = []
  //    msg_list.push('\"' + opt['method'] + '\"') if opt['method']
  //    msg_list.push('\"Wrong type of obj[val:' + opt['obj'] + ', type:' + typeof(opt['obj']) + ']\"')
  //    conlog.debug('Log.typeError', 'conlog.Log.warn(' + msg_list.join(',') + ')')
  //    eval('conlog.warn(' + msg_list.join(',') + ')')
}

// export const conlog = new ConLog({debug: true});
// ConLog ****** END
