// 共通定义：
// 定义常量、变量：（本文件中）
// 1. 常量： static CONSTANT_NAME: {type} = xxxx，使用： Modal.CONSTANT_NAME
// 2. 变量： variableName = xxxx，使用： Modal.VariableName

import { Modal as flowbiteModal } from 'flowbite';

// Modal ****** START
export class ConModal {
  // Class constants/variable *** START ***
  static ALLOW_DEBUG: boolean = true; // 允许调试开关，先开启才能调试，否则无法输出debug相关log
  static INS_NAME: string = 'modal';
  static BTN_ATTR: string = 'data-modal-target';

  // Instance constants/variable *** START ***
  debug_flg: boolean = false; // 默认非调试
  modalDom: any;
  modal: any;
  classObj: any;

  // Initialization function
  constructor(modalDom: any, options = {}) {
    this.classObj = Object.getPrototypeOf(this).constructor;
    
    // Get debug flag
    if (this.classObj.ALLOW_DEBUG) {
      this.debug_flg = true;
    }
    // if (this.debug_flg) console.debug('this.classObj', this.classObj);

    this.modalDom = modalDom;
    this.modal = new flowbiteModal(this.modalDom, options);
  }

  show() {
    if (this.debug_flg) console.debug(`modal[${this.modalDom.id}] show`);
    this.modal.show();
  }

  hide() {
    if (this.debug_flg) console.debug(`modal[${this.modalDom.id}] hide`);
    this.modal.hide();
  }

  bindBtn(btnDom) {
    btnDom.setAttribute('data-modal-toggle', this.modalDom.id);
    btnDom.setAttribute('data-modal-taget', this.modalDom.id);
  }
    
}
// Modal ****** END
