import { $, MstCom } from './common/_all';
import { ConModal } from './common/con_modal';
import { ConLog } from './common/con_log';
import '../packs-js/search/jquery_cookie';
import { ThemeToggle, applyInitialTheme } from '././darkmode';
const conlog = new ConLog({debug: false});

let cookieModal;
let modalInitialized = false;
let resizeTimer; // setTimeout for window resize

// When the window size changes doing
window.addEventListener('resize', function() {
    // Clears the previous setTimeout
    if (resizeTimer) clearTimeout(resizeTimer);
  
    // Set a new setTimeout to perform the desired action after the delay
    resizeTimer = setTimeout(function() {
        initFooterFix();
    }, 250); // The delay time can be adjusted as needed
});

// When the DOM is fully loaded doing
document.addEventListener('DOMContentLoaded', function() {
    initFooterFix();
    initOnlineService();
    initCookieAuthorization();
});

// Fix footer
function initFooterFix() {
    const footer = document.querySelector('footer');
    // footer fix
    if (footer) {
        const winHeight = window.innerHeight;
        const bodyHeight = document.documentElement.scrollHeight;
        if (winHeight >= bodyHeight) {
            footer.classList.add('fixed-bottom');
        } else {
            if (footer.classList.contains('fixed-bottom')) footer.classList.remove('fixed-bottom');
        }
    }
}

// Online service
function initOnlineService() {
    const btn = document.querySelector('[data-event=online-service]');
    const modelDom = document.getElementById('onlineServiceModal');
    
    if (!btn) {
        conlog.warn("Not found element DOM[.online-service]");
        return;
    }
    if (!modelDom) {
        conlog.warn("Not found element DOM[#onlineServiceModal]");
        return;
    }

    let serviceModal = new ConModal(modelDom);
    serviceModal.bindBtn(btn);

    btn.addEventListener('click', function() {
        serviceModal.show();
    })
}


// Function to initialize modal and set flag
function initCookieAuthorization() {

    // Close consent button event
    $('#close-consent').on('click', function () {
        let cookie = $.cookie('allow_all_cookie') || '{}';
        let cookieObject = JSON.parse(cookie);
        cookieObject.cookie_tips = {
            value: 'no',
            expires: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString()
        };
        updateCookie(cookieObject);
    });

    // Allow cookies button event
    $('#allow-cookies').on('click', function () {
        let cookie = $.cookie('allow_all_cookie') || '{}';
        let cookieObject = JSON.parse(cookie);
        cookieObject.cookie_tips = {
            value: 'yes',
            expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toISOString()
        };
        updateCookie(cookieObject);
    });
    // Check for cookie after 3 seconds
    setTimeout(checkCookieAndShowModal, 3000);
}

// Function to handle cookie checking and modal display
function checkCookieAndShowModal() {
    let cookie = $.cookie('allow_all_cookie');
    if (!cookie) {
        $('#cookieAuthorizationModal').removeClass('hidden');
    }
}

// Function to check if cookie is expired
function isCookieExpired(cookie) {
    let parsedCookie = JSON.parse(cookie);
    let cookieTips = parsedCookie.cookie_tips;
    return cookieTips && new Date(cookieTips.expires) < new Date();
}

// Function to update or set the cookie with specified data
function updateCookie(cookieData) {
    $.cookie('allow_all_cookie', JSON.stringify(cookieData),{ path: '/' });
    $('#cookieAuthorizationModal').addClass('hidden')
}

//dark mode
//document.addEventListener('DOMContentLoaded', () => {
//    applyInitialTheme();
//    new ThemeToggle('theme-toggle', 'theme-toggle-dark-icon', 'theme-toggle-light-icon');
//});

$(document).ready(function() {
    // 默认显示option_Chinese的内容在language-change-content里
    var defaultOption = $('#option_Chinese').html();
    $('#language-change-content').html(defaultOption);

   
    $('#language-change').click(function() {
        $('#modal3').toggleClass('hidden');
    });

    
    function updateLanguage(optionId) {
        var optionContent = $(optionId).html();
        $('#language-change-content').html(optionContent);
        $('#modal3').addClass('hidden');
    }

  
    $('#option_Chinese, #option_English, #option_Japanese').click(function() {
        updateLanguage('#' + this.id);  // 使用通用函数更新内容
    });
});